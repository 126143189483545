<template>
<v-app>
    <v-main style="padding: 0;">
        <v-container fluid>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <loading-overlay :loading="loading" />
              </v-col>
            </v-row>
            <div v-if="isVerified && !loading">
                <md-dialog-alert :md-active.sync="isDisplayDialog" :md-content="dialog.dialogMessage" :md-title="dialog.dialogTitle" md-confirm-text="OK" @md-closed="backToMerchant" />
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center">
                      <span class="transaction-title">
                          You are being charged by
                      </span>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <v-img :src="merchantLogo" alt="merchant logo" contain max-height="85" />
                  </v-col>
                </v-row>
                <div v-if="customerData && customerData.description">
                    <v-row>
                      <v-col>
                        <span class="transaction-label">Description:</span>
                        <span class="breakdown-value ml-3">{{customerData ? customerData.description: '-'}}</span>
                      </v-col>
                    </v-row>
                </div>
                <v-radio-group v-model="paymentOptionList" column>
                    <v-radio :label="defaultOptionLabel" value="default"></v-radio>
                    <div v-if="paymentOptionList === 'default'" class="mt-1 mb-6">
                          <break-down class="mt-1 pa-2" :title="`Balance of ${customerData.mobile}:`" :list="displayBalanceList" :merchants="merchants" :data="customerData" v-if="displayBalanceList.length"/>
                          <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="paymentOption" :amount="amountToPay" :button="submit" :merchants="merchants" :data="customerData" />
                    </div>
                    <!-- <v-radio label="Other payment options" value="other"></v-radio>
                    <div v-if="paymentOptionList === 'other'" class="mt-1 mb-10">
                        <div class="mb-3 ml-3">
                            <span class="transaction-label">SOON!</span>
                        </div>
                    </div> -->
                </v-radio-group>
            </div>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import LoadingOverlay from '@/views/component/LoadingOverlay.vue';
import BreakDown from '@/views/component/BreakDown.vue';
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import {
    isValidParameter,
    invalidParameterErrorCode,
    convertParamStringToArray,
    getBalanceBreakdown,
    getPaymentOption,
    getAmountToPay,
    getBalanceParameters,
    isValidPaymentOption,
    updateBalanceBreakdown,
    updateBalanceBreakdownValue,
    getDefaultpaymentOptionLabel,
    confirmPayment,
    getChargeParameters,
    successCharge,
    errorCharge,
    isError500,
    shouldRedirectbackToMerchant,
} from '@/utils/params.js';

export default {
    name: 'Transaction',
    components: {
        LoadingOverlay,
        BreakDown
    },
    computed: {
        ...mapGetters([
            'customerData'
        ]),
        balance(item) {
            return
        }
    },
    data: () => ({
        loading: true,
        isVerified: false,
        paymentOption: [],
        balanceBreakdown: [],
        displayBalanceList: [],
        balances: {},
        amountToPay: [],
        paymentOptionList: 'default',
        data: {},
        isDisplayDialog: false,
        dialog: {
            dialogMessage: '',
            dialogTitle: '',
            redirectUrl: ''
        },
        merchants: {},
        merchantLogo: '',
        defaultOptionLabel: undefined
    }),
    mounted() {
        console.log('mounted')
        this.$store.dispatch('NavbarState', {state: false});
        this.verify()
        this.getImageSrc()
    },
    watch: {
        balances(val) {
            console.log(val)
        },
        deep: true
    },
    methods: {
        async verify() {
          console.log('verify')
            this.isVerified = false
            this.loading = true
            if (isValidParameter(this.customerData)) {
                this.paymentOption = convertParamStringToArray(this.customerData.PO)
                this.balanceBreakdown = getBalanceBreakdown(this.paymentOption)
                console.log(this.balanceBreakdown)
                this.amountToPay = convertParamStringToArray(this.customerData.amount)
                this.defaultOptionLabel = this.getDefaultPaymentOption()
                this.getBalance()
            } else {
              const errorCode = invalidParameterErrorCode(this.customerData)
              console.log('401: Invalid parameters - ' + errorCode)
              this.$router.replace({path:'401', query: { errorCode: errorCode }})
            }
        },
        async getBalance() {
          console.log('balance')
          console.log(this.balanceBreakdown)
            for (const option in this.balanceBreakdown) {
                const paymentType = getPaymentOption(this.balanceBreakdown, option)
                const amount = getAmountToPay(this.paymentOption, this.amountToPay, paymentType)
                const params = getBalanceParameters(this.customerData, paymentType, amount)
                this.data = params
                console.log('valid? ', isValidPaymentOption(paymentType))
                if (isValidPaymentOption(paymentType)) {
                    const response = await API.getBalance(params)
                    if (response.balance) {
                      console.log('has balance')
                        this.balanceBreakdown = updateBalanceBreakdownValue(this.balanceBreakdown, paymentType, response.balance)
                        this.displayBalanceList = updateBalanceBreakdown(this.balanceBreakdown, paymentType, response.balance)
                        this.isVerified = true
                        if(response.merchantCode) {
                          this.merchants[response.merchantCode] = response
                          this.getImageSrc()
                          this.defaultOptionLabel = this.getDefaultPaymentOption()
                        }
                    } else if(response.merchantCode) {
                        this.balanceBreakdown = updateBalanceBreakdownValue(this.balanceBreakdown, paymentType, response.balance)
                        this.displayBalanceList = updateBalanceBreakdown(this.balanceBreakdown, paymentType, response.balance)
                        this.isVerified = true
                        this.merchants[response.merchantCode] = response
                        this.getImageSrc()
                        this.defaultOptionLabel = this.getDefaultPaymentOption()
                    } else {
                      console.log(response.error)
                      if(response.error) {
                        console.log('401: ' + JSON.stringify(response.error.message ?? response.error))
                        this.loading = false
                        if((response.error.message ?? response.error).includes("Not Found")) {
                          this.$router.replace({path:'404', query: { errorCode: '20001' }})
                        } else if(response.error.message === "Network Error") {
                          this.$router.replace({path:'404', query: { errorCode: '20002' }})
                        } else if(response.error.includes("Bad Gateway")) {
                          this.$router.replace({path:'500', query: { errorCode: '20003' }})
                        } else if((response.error.message ?? response.error).includes("Gateway Time-out")) {
                          this.$router.replace({path:'500', query: { errorCode: '20004' }})
                        } else if(response.error === "Invalid Payment Method.") {
                          this.$router.replace({path:'401', query: { errorCode: '10100' }})
                        } else if(response.error === "Payment Method is not assigned to Merchant.") {
                          this.$router.replace({path:'401', query: { errorCode: '10300' }})
                        } else {
                          this.$router.replace({path:'401', query: { errorCode: '10200' }})
                        }
                      } else {
                        this.$router.replace({path:'401', query: { errorCode: '10200' }})
                      }
                    }
                } else {
                  console.log('401: invalid payment option')
                  this.loading = false
                  this.$router.replace({path:'401', query: { errorCode: '10100' }})
                }
            }
            this.loading = false
        },
        getImageSrc() {
          if(this.merchants && this.merchants[this.customerData.MC]) {
            this.merchantLogo = this.merchants[this.customerData.MC].merchantLogo
          } else {
            switch(this.customerData.MC) {
              case "CliqqGrocery":
                this.merchantLogo = "/img/cliqqGrocery.png"
                break
              case "MarioDeBoro":
                this.merchantLogo = "/img/zeepmall.png"
                break
              case "ZeepMall":
                this.merchantLogo = "/img/zeepmall.png"
              default:
                this.merchantLogo = "/img/PayConnect.png"
                break
            }
          }
        },
        back() {
            this.$router.back()
        },
        getDefaultPaymentOption() {
            return getDefaultpaymentOptionLabel(this.paymentOption, this.merchants, this.customerData.MC)
        },
        submit() {
            if (this.data.paymentCode.includes('Wallet')) {
                this.$router.push({
                    name: 'pin',
                    params: {
                        details: this.data,
                        merchants: this.merchants
                    }
                });
            } else {
                this.charge()
            }
        },
        async charge() {
            this.loading = true
            console.log(this.balanceBreakdown)
            const paymentType = getPaymentOption(this.balanceBreakdown, 0)
            const amount = getAmountToPay(this.paymentOption, this.amountToPay, paymentType)
            const params = getChargeParameters(this.customerData, paymentType, amount, '0000')
            const verify = await API.charge(params)
            console.log(verify)
            if (verify && verify.statusCode === '200') {
                if(verify.checkoutUrl && verify.isThirdPartyGateway) {
                  //window.location.href = verify.checkoutUrl;
                  this.$router.push({
                      name: 'GCash',
                      params: {
                          url: verify.checkoutUrl.replace("http:", "https:"),
                          retry: this.customerData.url
                      }
                  });
                  this.loading = false
                  //window.location.replace(verify.checkoutUrl)
                } else {
                  this.dialog = successCharge(this.merchants, this.customerData, verify.successUrl)
                  this.loading = false
                  this.isDisplayDialog = true
                }
            } else {
              console.log("error: ", verify)
                this.dialog = errorCharge(verify, verify.failUrl)
                this.loading = false
                this.isDisplayDialog = true
            }
        },
        backToMerchant() {
          console.log(this.dialog)
            console.log("is error 500:", isError500(this.dialog.redirectUrl))
            if (!isError500(this.dialog.redirectUrl)) {
              console.log("should redirect back to merchant:", shouldRedirectbackToMerchant(this.dialog.dialogMessage))
              if (shouldRedirectbackToMerchant(this.dialog.dialogMessage)) {
                this.loading = true
                var url = this.dialog.redirectUrl
                var pattern = new RegExp(/\?.+=.*/g);
                if (pattern.test(url)) {
                    url = `${url}&CRN=${this.customerData.CRN}`
                } else {
                    url = `${url}?CRN=${this.customerData.CRN}`
                }
                window.location.replace(url)
              }
            } else {
                //this.$router.replace({path:'500', query: { errorCode: '10008' }})
            }
        }
    },

};
</script>

<style scoped>
.transaction-label {
    font-weight: bold;
    font-size: 1em;
}

.transaction-title {
    font-weight: 500;
    font-size: 1.2em;
    text-align: center;
}

.breakdown-label {
    font-weight: 400;
    font-size: 1em;
    color: black;
}

.breakdown-value {
    font-weight: 400;
    font-size: 1em;
    color: black;
}
</style>
